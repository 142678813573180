<template>
  <vs-input
    class="fill"
    :label="
      lang.botMaker.addBot.intentionTriggerSecondLevel.secondLevelDerivation
        .description[languageSelected]
    "
    v-model="bot.secondLevelMessage"
    :danger="validations.secondLevelMessage"
    :danger-text="
      lang.botMaker.addBot.intentionTriggerSecondLevel.secondLevelDerivation
        .errorMessage[languageSelected]
    "
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BotMakerService from '../../../../../services/botMaker.service'

export default {
  name: 'IntentSecondLevelDerivation',
  props: {
    isEditing: Boolean
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['bot', 'validations'])
  },
  watch: {
    languageSelected() {
      if (!this.isEditing) {
        this.bot.secondLevelMessage = this.lang.botMaker.addBot.intentionTriggerSecondLevel.secondLevelDerivation.message[
          this.languageSelected
        ]
      }
    }
  },
  mounted() {
    if (!this.isEditing) {
      this.bot.secondLevelMessage = this.lang.botMaker.addBot.intentionTriggerSecondLevel.secondLevelDerivation.message[
        this.languageSelected
      ]
    }
    if (this.bot.activeVersion) {
      BotMakerService.getDialogs(this.bot.activeVersion).then(response => {
        if (this.bot.secondLevelMessage) {
          return
        }

        const botDialogs = response.data
        const intentDerivesToHuman = this.bot.intents.find(
          i => i.derivesToAgent
        )

        // old only intent messages with new condition format
        const dialogResponse = botDialogs.find(d => {
          const params = d.condition.params

          return (
            params.length === 1 &&
            params[0].intent &&
            params[0].intent ===
              (intentDerivesToHuman && intentDerivesToHuman.name)
          )
        })

        this.bot.secondLevelMessage = dialogResponse
          ? dialogResponse.responses[0].responseText
          : ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#intent-second-level-derivation {
  padding-top: 30px;
}
.vs-con-input-label {
  width: auto;
}
</style>
