<template>
  <div class="users-table">
    <vs-table
      :data="bot.queues"
      pagination
      :max-items="maxItems"
      search
      :hoverFlat="true"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th>
          {{ lang.botMaker.addBot.manageQueues.table.name[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.botMaker.addBot.manageQueues.table.description[languageSelected] }}
        </vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.queue.name">{{ tr.queue.name }}</vs-td>
          <vs-td :data="tr.queue.description">{{ tr.queue.description }}</vs-td>
          <vs-td>
            <div class="table-actions">
              <vs-button
                v-if="isAuthAux('konecta.bot_maker.edit_bot')"
                color="warning"
                type="flat"
                icon="clear"
                @click="remove(tr.queue._id)"
              ></vs-button>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { USERS_PLATFORMS } from '@/constants/constants'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'QueuesTable',
  components: {
  },
  props: {
    users: {
      type: Array
    },
    maxItems: {
      type: Number,
      default: 5
    },
    onEdit: Function,
    onDelete: Function
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      USERS_PLATFORMS
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('bots', [
      'bot',
    ]),
    // disableDelete() {
    //   if (this.useStaffManagement) {
    //     return false
    //   }
    //   const sessionRoles = this.session && this.session.roles
    //   const deleteUsersRoles = ['company_superadmin', 'company_roles_manager']

    //   return !sessionRoles.some(role => deleteUsersRoles.includes(role))
    // }
  },
  methods: {
    ...mapMutations('bots', ['SET_USER_TO_EDIT', 'UPDATE_ADD_USER_PROMPT']),
    remove(id) {
      if (this.onDelete) {
        this.onDelete(id)
        return
      }

      this.bot.queues = this.bot.queues.filter(q => {
        return q.queue._id !== id
      })
    },
    limitText(count) {
      return `+${count}`
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.users-table {
  .table-actions {
    display: flex;
    justify-content: flex-end;
  }
  .with-space-to-the-right {
    margin-right: 5px;
  }

  .vs-table--tbody-table
    .tr-table.hoverFlat:not(.activeEdit):not(.is-expand):hover {
    opacity: 1 !important;
  }

  .vs-table--tbody-table .tr-table .tr-values {
    height: 100px;
  }

  .vs-table--content {
    overflow: unset !important;
  }

  .vs-con-table .vs-con-tbody {
    overflow: unset !important;
  }

  .multiselect {
    width: 300px;
    &--disabled {
      .multiselect__select {
        height: 42px;
      }
    }
    .multiselect__content-wrapper {
      font-size: 1rem;
      max-height: 160px !important;
      z-index: 300 !important;
    }
    .multiselect__input {
      font-size: 1rem;
    }
  }
}
</style>
