<template>
  <div id="select-queues-prompt">
    <vs-prompt
      :is-valid="isValid"
      :vs-active.sync="openSelectQueue"
      @vs-accept="onSelectQueues"
      @vs-close="onClose"
      @vs-cancel="onClose"
      :vs-accept-text="
        lang.botMaker.addBot.manageQueues.dialogSelectQueues.selectQueues[
          languageSelected
        ]
      "
      :vs-title="
        lang.botMaker.addBot.manageQueues.dialogSelectQueues.title[
          languageSelected
        ]
      "
      :vs-cancel-text="lang.botMaker.cancel[languageSelected]"
    >
      <kona-alert icon="AlertCircleIcon" v-show="!isValid">
        {{
          lang.botMaker.addBot.manageQueues.dialogSelectQueues.alert[
            languageSelected
          ]
        }}
      </kona-alert>

      <vs-table
        multiple
        v-model="selectedQueues"
        :data="queueList"
        class="queue-list"
        search
        paginated
        :no-data-text="lang.general.noDataText[languageSelected]"
      >
        <template slot="thead">
          <vs-th sort-key="name">{{
            lang.botMaker.addBot.manageQueues.table.name[languageSelected]
          }}</vs-th>
          <vs-th sort-key="email">{{
            lang.botMaker.addBot.manageQueues.table.description[languageSelected]
          }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
            <vs-td :data="data[indextr].description">{{ data[indextr].description }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-prompt>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'SelectQueues',
  props: {
    togglePrompt: Function,
  },
  data() {
    return {
      selectedQueues: [],
      isMultiple: false
    }
  },
  computed: {
    ...mapGetters('bots', ['bot', 'queues']),
    ...mapState(['lang', 'languageSelected', 'session']),
    isValid() {
      return this.selectedQueues.length > 0
    },
    openSelectQueue: {
      get() {
        return this.$store.state.bots.isSelectQueuePromptOpened
      },
      set(value) {
        this.UPDATE_SELECT_QUEUE_PROMPT(value)
      }
    },
    queueList() {
      // Remove the current queue from the list and selected

      const queuesSelected = this.bot.queues.map(u => u.queue._id)
      return this.queues.filter(u => {
        return !this._.includes(queuesSelected, u._id)
      })
    }
  },
  methods: {
    ...mapMutations('bots', ['UPDATE_SELECT_QUEUE_PROMPT', 'ADD_QUEUES']),
    ...mapActions('bots', ['LOAD_QUEUES']),
    onSelectQueues() {
      // let queues = [...this.bot.queues]
      // console.log('QUEUES', queues, this.selectedQueues);
      let queues = this.selectedQueues.map(q => ({ queue: q }));
      queues = this._.uniq(queues, false, function(q){ console.log('QUEUE', q.queue); return q.queue._id; });
      
      // Sort queues by name
      queues = this._.orderBy(queues, function(q){ return q.queue.name; }, 'asc')

      this.ADD_QUEUES(queues)
      if (this.togglePrompt) {
        this.togglePrompt()
      }
    },
    onClose() {
      this.UPDATE_SELECT_QUEUE_PROMPT(false)
      if (this.togglePrompt) {
        this.togglePrompt()
      }
    }
  },
  mounted() {
    this.$vs.loading()
    let self = this
    this.LOAD_QUEUES().finally(() => {
      self.$vs.loading.close()
    })
  }
}
</script>

<style type="scss">
#select-queues-prompt {
  max-height: 300px;
}

.vs-dialog {
  max-width: 700px !important;
}

.queue-list {
  max-height: 400px;
  overflow-y: auto;
}

.con-vs-dialog .vs-dialog footer {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}

.con-vs-dialog .vs-dialog footer .vs-button {
  margin-left: 0.5rem;
}
</style>

<style>
#select-queues-prompt footer {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}

#select-queues-prompt footer .vs-button {
  margin-left: 0.5rem;
}
#select-queues-prompt footer span.vs-button-text.vs-button--text {
  color: white !important;
}
</style>
