<template>
  <div class="users-table">
    <vs-table
      :data="users"
      pagination
      :max-items="maxItems"
      search
      :hoverFlat="true"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th>
          {{ lang.botMaker.addBot.manageUsers.table.name[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.botMaker.addBot.manageUsers.table.email[languageSelected] }}
        </vs-th>
        <vs-th>
          {{ lang.botMaker.addBot.manageUsers.table.role[languageSelected] }}
        </vs-th>
        <vs-th v-if="showChannels">
          {{
            lang.botMaker.addBot.manageUsers.table.channels[languageSelected]
          }}
        </vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.name">{{ tr.name }}</vs-td>
          <vs-td :data="tr.email">{{ tr.email }}</vs-td>
          <vs-td :data="tr.roles">
            <ul
              v-if="
                useStaffManagement && tr.staffRoles && tr.staffRoles.length > 0
              "
            >
              <li v-for="(role, index) in tr.staffRoles" :key="index">
                - {{ role.name }}
              </li>
            </ul>
            <ul
              v-else-if="
                !useStaffManagement && tr.roles && tr.roles.ids.length > 0
              "
            >
              <li v-for="(role, index) in tr.roles.ids" :key="index">
                - {{ role | role_name(languageSelected) }}
              </li>
            </ul>
          </vs-td>
          <vs-td :data="usersByChannelMap[tr.id]" v-if="showChannels">
            <multiselect
              v-model="usersByChannelMap[tr.id]"
              :placeholder="
                lang.botMaker.addBot.intentionTriggerSecondLevel.table
                  .selectChannels[languageSelected]
              "
              tagPlaceholder=""
              :show-labels="false"
              label="name"
              track-by="value"
              :options="USERS_PLATFORMS"
              :multiple="true"
              :taggable="true"
              :limit="2"
              :limit-text="limitText"
            />
          </vs-td>
          <vs-td>
            <div class="table-actions">
              <vs-button
                class="with-space-to-the-right"
                color="warning"
                type="flat"
                icon="edit"
                v-if="onEdit && (isAuthAux('konecta.users.edit') || tr.id === session.user.id)"
                @click="editUser(tr.id)"
              ></vs-button>
              <vs-button
                v-if="isAuthAux('konecta.users.delete') && tr.id !== session.user.id"
                :disabled="disableDelete"
                color="warning"
                type="flat"
                icon="clear"
                @click="remove(tr.id)"
              ></vs-button>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { USERS_PLATFORMS } from '@/constants/constants'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'UsersTable',
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  props: {
    users: {
      type: Array
    },
    maxItems: {
      type: Number,
      default: 5
    },
    showChannels: {
      type: Boolean,
      default: false
    },
    onEdit: Function,
    onDelete: Function
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      USERS_PLATFORMS
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    ...mapGetters('bots', ['bot', 'usersByChannelMap']),
    disableDelete() {
      if (this.useStaffManagement) {
        return false
      }
      const sessionRoles = this.session && this.session.roles
      const deleteUsersRoles = ['company_superadmin', 'company_roles_manager']

      return !sessionRoles.some(role => deleteUsersRoles.includes(role))
    }
  },
  methods: {
    ...mapMutations('bots', ['SET_USER_TO_EDIT', 'UPDATE_ADD_USER_PROMPT']),
    editUser(id) {
      if (this.onEdit) {
        this.onEdit(id)
        return
      }

      this.SET_USER_TO_EDIT(id)
      this.UPDATE_ADD_USER_PROMPT(true)
    },
    remove(id) {
      if (this.onDelete) {
        this.onDelete(id)
        return
      }

      this.bot.service.users = this.bot.service.users.filter(u => {
        return u.id !== id
      })
    },
    limitText(count) {
      return `+${count}`
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.users-table {
  .table-actions {
    display: flex;
    justify-content: flex-end;
  }
  .with-space-to-the-right {
    margin-right: 5px;
  }

  .vs-table--tbody-table
    .tr-table.hoverFlat:not(.activeEdit):not(.is-expand):hover {
    opacity: 1 !important;
  }

  .vs-table--tbody-table .tr-table .tr-values {
    height: 100px;
  }

  .vs-table--content {
    overflow: unset !important;
  }

  .vs-con-table .vs-con-tbody {
    overflow: unset !important;
  }

  .multiselect {
    width: 300px;
    &--disabled {
      .multiselect__select {
        height: 42px;
      }
    }
    .multiselect__content-wrapper {
      font-size: 1rem;
      max-height: 160px !important;
      z-index: 300 !important;
    }
    .multiselect__input {
      font-size: 1rem;
    }
  }
}
</style>
