<template>
  <add-bot-section
    id="queue-administration"
    :title="lang.botMaker.addBot.manageQueues.title[languageSelected]"
  >
    <template slot="actions">
      <select-queues
        v-if="isSelectQueuesOpen"
        :toggle-prompt="toggleIsSelectQueuesOpen"
      />
      <vs-button
        color="primary"
        type="filled"
        icon="book"
        @click="selectQueues"
      >
        {{ lang.botMaker.addBot.manageQueues.button[languageSelected] }}
      </vs-button>
    </template>
    <template slot="whole-w" v-if="hasQueues">
      <queues-table :queues="queues" />
    </template>
    <template slot="whole-w" v-else>
      {{ lang.botMaker.addBot.manageQueues.empty.title[languageSelected]}}
    </template>
  </add-bot-section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import SelectQueues from './Queues/SelectQueues'
import QueuesTable from './Queues/QueuesTable'
// import Roles from '../../../../models/Roles'
import AddBotSection from './Components/AddBotSection'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'QueuesAdministration',
  components: {
    AddBotSection,
    SelectQueues,
    QueuesTable
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      isSelectQueuesOpen: false,
      queuesTableProps: {
        isMultiple: false,
        isSearchable: true,
        isPaginated: true
      }
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    ...mapGetters('bots', [
      'bot',
      'validations',
      // 'users',
      'sectionReady'
    ]),
    hasQueues() {
      if (this.bot && this.bot.queues) {
        return this.bot.queues.length > 0
      }

      return false
    },
    queues() {
      if (this.bot && this.bot.queues) {
        return this.bot.queues
      }

      return []
    },
  },
  methods: {
    ...mapMutations('bots', [
      'UPDATE_SELECT_QUEUE_PROMPT',
    ]),
    selectQueues: function() {
      this.isSelectQueuesOpen = true;
      this.UPDATE_SELECT_QUEUE_PROMPT(true)
    },
    toggleIsSelectQueuesOpen: function() {
      this.isSelectQueuesOpen = !this.isselectQueuesOpen
    }
  },
  mounted() {
    this.sectionReady.queuesAdministration = true
  }
}
</script>
