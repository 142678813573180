<template>
  <div class="widget-settings" v-if="isWidgetSettingsOpen">
    <vs-row>
    <div class="back-button-container">
            <a
              @click="closeWidgetSettings()"
              class="backButton"
              id="back-btn"
              plain
              :class="{ active: false }"
            >
              <!-- <img
                  class="flag"
                  :src="require(`@/assets/images/flags/${language.code}.png`)"
                /> -->
              <p class="font-medium block notification-title mr-2">
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="inline-block back-button-icon"
                  svgClasses="h-7"
                />
              </p>
              <h2>{{lang.botMaker.addBot.widget.webPanelTitle[languageSelected]}}</h2>
            </a>
          </div>
    </vs-row>
    <vs-row>
      <BotWidgetSettings
        v-if="active"
        ref="botWidgetSettings"
        @closePopup="active = false"
        :key="keyBotWidget"
      />
     </vs-row>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import BotWidgetSettings from './BotWidgetSettings.vue'
import { MAILS } from '@/constants/constants'
export default {
  name: 'widgetSettings',
  components: {
    BotWidgetSettings,
    // KonaPopup: () => import('../../../../../components/KonaPopup.vue')
  },
  beforeDestroy() {
    this.active = false
  },
  data() {
    return {
      MAILS,
      keyBotWidget: new Date().getTime(),
      active: true
    }
  },
  watch: {
    active() {
      this.keyBotWidget = new Date().getTime()
    }
  },
  methods: {
    ...mapMutations('bots', [
      'SET_WIDGET_SETTINGS_OPEN',
    ]),
    open() {
      if (!this.companyAuthKey || !this.companyId) {
        this.$refs.konaPopup.openPopup()
      } else {
        this.active = true
      }
    },
    closeWidgetSettings() {
      this.SET_WIDGET_SETTINGS_OPEN(false);
    }
  },
  computed: {
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady', 'isWidgetSettingsOpen']),
    ...mapState(['lang', 'languageSelected', 'companyAuthKey', 'companyId'])
  }
}
</script>

<style lang="scss">
.widget_settings_popup.con-vs-popup {
  .vs-popup {
    width: 900px;
  }
}
.widget_settings_popup {
  .vs-popup--content {
    padding-top:0;
  }
}
</style>

<style lang="scss">
.widget-settings {
  .back-button-container {
    display: inline-block;
  }
  .back-button-icon {
    svg{
      vertical-align: middle;
    }
  }
  .back-button-span {
    vertical-align: text-bottom;
  }
  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active,
    // &:hover {
    //   color: rgba(var(--vs-primary), 1);
    // }

    .flag {
      margin-right: 5px;
      margin-bottom: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
}
.add-bot-section {
  margin: 30px 20px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>



<style lang="scss">

</style>